.main {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.375rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  justify-content: space-between;
}

.wrapper {
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}

.sectionDisplay {
  background-color: rgba(255, 255, 255, 0.1);
  background-size: contain;
  backdrop-filter: blur(0.625rem);
  border-radius: 0.5rem;
  max-width: 25.5rem;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("../assets/images/sectionDisplay.png");
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  padding: 0.375rem 0.5rem;
  background-size: 100% 100%;
  cursor: pointer;
}

.seeMore svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.sectionDisplay:hover .sectionDisplayArrow {
  margin-right: -4px;
}

.sectionDisplayInset {
  height: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: inset 0 0.1875rem 2.0625rem 0 rgba(0, 0, 0, 0.48);
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}

.sectionDisplayContent {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0.75rem 1.125rem;
  padding-right: 0.75rem;
  background-image: linear-gradient(to right, #e1007f, #0050f0, #ff0090);
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sectionDisplayTitle {
  font-size: 0.875rem;
}

.sectionDisplayArrow {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.sectionDisplayArrow svg path {
  fill: #fff;
}

.sectionDisplayArrow {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sectionDisplaySubtitle {
  text-align: left;
  font-size: 0.625rem;
  text-shadow: 0px 3px 6px #00000029;
  color: #46f2df;
  opacity: 1;
  margin-top: 8px;
}

.sectionDisplayContentGasPrice,
.sectionDisplayGasPrice {
  display: flex;
  height: 100%;
  margin-left: 14px;
}

.sectionDisplayGasPrice {
  justify-content: center;
  flex-direction: column;
}

.sectionDisplayContentGasPrice .sectionDisplaySubtitle {
  color: #e1007f;
}

.text {
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  text-shadow: 0rem 0rem 0.625rem rgba(255, 255, 255, 0.8);
}

.flipNonNumbers {
  opacity: 0;
  margin: 0 24px;
}

.flipNumbers {
  background-image: linear-gradient(
    180deg,
    #100f0f00 0%,
    #0e0d0d 21%,
    #000000 72%,
    #00000000 100%
  );
  margin: 0 2px;
}

.coinsToday {
  background-image: url("../assets/images/sectionDisplay.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: max-content;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(0.625rem);
  border-radius: 2rem;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 85.875rem;
  padding-right: 4.375rem;
  padding-left: 4.375rem;
  height: 11rem;
  margin: 0 3rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  box-shadow: 0 0 3rem rgba(255, 0, 144, 0.25);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.coinsTodayText {
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: #e1007f;
  text-shadow: 0rem 0.1875rem 0.375rem #00000029;
  padding-left: 2.5rem;
  white-space: wrap;
}

.coinsGrid {
  padding-left: 58px;
  padding-right: 58px;
}

.headersGrid,
.valuesGrid {
  display: grid;
  grid-template-columns: 1.5fr 1.25fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  align-items: center;
}

.topGrid {
  margin-top: 1.5rem;
}

.topGrid .headersGrid,
.topGrid .valuesGrid {
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
}

.coinsHeadersGrid,
.coinsValuesGrid {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
}

.headersGrid,
.coinsHeadersGrid {
  padding: 0 8px;
  margin-bottom: 12px;
  font-size: 10px;
  line-height: 15px;
  color: #c67ba6;
}

.valuesGrid,
.coinsValuesGrid {
  padding: 0.625rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: rgba(255, 255, 255, 0.15);
  margin-bottom: 0.75rem;
}

.name {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.name span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 18px;
}

.seeMore {
  text-align: right;
  padding-right: 16px;
  color: #e1007f;
  text-decoration: underline;
  cursor: pointer;
  width: max-content;
  margin-left: auto;
}

.price {
  color: #49b7ab;
}

.coinGridItem {
  color: #ac9ba5;
}

.seeFullList {
  background-color: #fff !important;
  color: #ff0086 !important;
  padding-left: 24px;
  padding-right: 24px;
}

.seeFullListButton {
  transform: translateY(-16px);
  -webkit-transform: translateY(-16px);
  -moz-transform: translateY(-16px);
  -ms-transform: translateY(-16px);
  -o-transform: translateY(-16px);
}

.hideBtn {
  position: sticky;
  bottom: 0.5rem;
}

.seeFullListButton svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.hideBtn svg {
  transform: rotate(90deg) !important;
  -webkit-transform: rotate(90deg) !important;
  -moz-transform: rotate(90deg) !important;
  -ms-transform: rotate(90deg) !important;
  -o-transform: rotate(90deg) !important;
}

.seeFullList svg {
  margin-left: 16px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.seeFullList svg path {
  fill: #ff0086;
}

.blur {
  position: absolute;
  bottom: 30px;
  content: "";
  width: 100%;
  max-height: 197px;
  height: 100%;
  background: linear-gradient(
    180deg,
    #100c1300 0%,
    #100c13e3 71%,
    #100c13 100%
  );
}

.coinsGridContent {
  position: relative;
}

.sectionHeader {
  padding-left: 52px;
}

.contractsDeployed {
  background-image: url("../assets/images/sectionDisplay.png");
  background-repeat: no-repeat;
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  padding-left: 64px;
  margin: 0 32px;
  max-width: none;
}

.contractsDeployed .coinsTodayText {
  padding-left: 24px;
  padding-right: 0px;
}

.contractsDeployed .flipNonNumbers {
  margin: 0 16px;
}

.contractsGrid {
  padding-left: 40px;
  padding-right: 40px;
}

.contractsGrid .valuesGrid,
.contractsGrid .address {
  font-size: 0.6875rem;
}

.coinsValuesGrid .address,
.coinsValuesGrid .launched {
  font-size: 1rem;
}

.launched {
  min-width: 0;
}

.address {
  color: #fff;
}

.contractsGrid {
  margin-top: 34px;
  margin-bottom: 32px;
}

.splineGraph {
  margin-top: 0px;
  margin-bottom: 50px;
  padding-left: 32px;
  padding-right: 32px;
}

.coinsGrid {
  margin-bottom: 80px;
}

.text {
  margin-bottom: 3rem;
}

.main {
  margin-top: 3rem;
  margin-bottom: 8rem;
}

.copy {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 64px;
  color: #e1007f;
}

.coinLeft,
.coinRight {
  position: absolute;
}

.coinLeft {
  left: 4rem;
  z-index: 1;
  top: -5rem;
  width: 12rem;
}

.coinRight {
  right: -4rem;
  z-index: 1;
  top: -7.5rem;
  width: 12rem;
}

.imgContainer {
  overflow: hidden;
  padding-bottom: 150px;
}

@media (max-width: 768px) {
  .sectionDisplay {
    max-width: 100%;
    width: 100%;
    padding: 6px;
  }

  .sectionDisplayTitle {
    font-size: 12px;
  }

  .sectionDisplayContent {
    font-size: 14px;
    padding: 8px 12px;
    padding-right: 8px;
    max-width: 98%;
    margin: 0 auto;
  }

  .sectionDisplayContentGasPrice,
  .sectionDisplayGasPrice {
    margin-left: 10px;
  }

  .text {
    font-size: 20px;
    max-width: 15ch;
    margin: auto;
    line-height: 1.25;
  }

  .coinsToday {
    padding: 8px;
    max-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    height: 6.25rem;
  }

  .coinsTodayText {
    font-size: 12px;
    line-height: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .coinsGrid {
    padding-left: 16px;
    padding-right: 16px;
  }

  .coinRight {
    opacity: 0;
  }

  .coinsToday {
    border-radius: 0;
    height: 4.125rem;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    margin-left: 16px;
    margin-right: 16px;
    box-shadow: none;
  }

  .headersGrid,
  .valuesGrid {
    grid-template-columns: 1fr 0.9fr auto;
  }

  .headersGrid {
    padding: 0 8px;
    margin-bottom: 8px;
    font-size: 8px;
    line-height: 12px;
  }

  .valuesGrid {
    padding: 8px;
    font-size: 14px;
  }

  .name span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name img {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  .price {
    font-size: 12px;
  }

  .coinGridItem {
    font-size: 12px;
  }

  .seeFullList {
    padding-left: 16px;
    padding-right: 16px;
  }

  .seeFullListButton {
    transform: translateY(-12px);
  }

  .seeFullList svg {
    margin-left: 12px;
  }

  .blur {
    max-height: 123px;
  }

  .contractsDeployed {
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 16px;
  }

  .contractsDeployed .coinsTodayText {
    padding-left: 6px;
    line-height: 10px;
    padding-right: 0;
    font-size: 8px;
  }

  .contractsDeployed .flipNonNumbers {
    margin: 0;
  }

  .contractsGrid {
    padding-left: 24px;
    padding-right: 24px;
  }

  .contractsGrid .headersGrid,
  .contractsGrid .valuesGrid {
    flex-wrap: wrap;
  }

  .topGrid .headersGrid,
  .topGrid .valuesGrid {
    grid-template-columns: 1fr 0.9fr auto;
  }

  .launched {
    min-width: 9.375rem;
  }

  .address {
    font-size: 14px;
  }

  .contractsGrid {
    margin-top: 18px;
    margin-bottom: 24px;
  }

  .splineGraph {
    margin-bottom: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .coinsGrid {
    margin-bottom: 50px;
  }

  .seeFullList {
    font-size: 14px;
  }

  .text {
    margin-bottom: 4%;
  }

  .main {
    margin-top: 24px;
    margin-bottom: 70px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .copy {
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .coinLeft,
  .coinRight {
    position: absolute;
  }

  .coinLeft {
    left: -8px;
    top: -72px;
    width: 70px;
    z-index: -1;
  }

  .coinRight {
    right: -16px;
    top: -75px;
    width: 120px;
  }

  .imgContainer {
    padding-bottom: 41px;
  }

  .seeMore {
    padding-right: 0.125rem;
  }
}

.container {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
  overflow-x: hidden;
  position: relative;
  flex-direction: column;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-size: cover;
  height: 100%;
  background-image: url("../assets/images/comingSoonBg.png");
}

.analysisBg {
  background-image: url("../assets/images/BG.png");
}

.header {
  font-size: 1.625rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.subHeader {
  font-size: 1.25rem;
  text-align: center;
  color: #e1007f;
  margin-bottom: 2rem;
}

.subText {
  font-size: 0.9375rem;
  text-align: center;
}

.content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10%;
  flex-direction: column;
}

.coin {
  width: 11.875rem;
  position: absolute;
  top: 5rem;
  z-index: 1;
}

.coin img {
  width: 100%;
}

.coinRight {
  width: 8.8125rem;
  position: absolute;
  right: -4.5rem;
  bottom: 2rem;
}

.coinRight img {
  width: 100%;
}

.title {
  font-size: 2.5rem;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 7rem;
  z-index: 1;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.8);
}

.left,
.right {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2.5rem;
  position: relative;
  margin-bottom: 3rem;
}

.leftBg {
  top: 0;
  left: 0;
  height: 100%;
  width: 106%;
  position: absolute;
  background-color: rgba(225, 0, 127, 0.17);
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  background-image: url("../assets/images/comingSoonBg.png");
  background-size: cover;
}

.rightBg {
  top: 0;
  left: -6%;
  height: 100%;
  width: 106%;
  position: absolute;
  background-image: url("../assets/images/BG.png");
  background-size: cover;
  background-color: rgba(0, 153, 255, 0.17);
  clip-path: polygon(11.25% 0, 100% 0, 100% 100%, 1.25% 100%);
}

.option1 {
  margin-top: 2rem;
}

.option2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 1000px) {
  .section {
    flex-direction: column;
  }

  .title {
    position: relative;
    top: 0;
    margin-bottom: 1rem;
  }
  .left,
  .right {
    min-height: 345px;
    padding: 1rem;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
  }

  .left {
    margin-bottom: 1rem;
    margin-top: 4rem;
  }

  .leftBg,
  .rightBg {
    clip-path: none;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .content {
    margin-top: -25%;
  }
}

.container {
  background: transparent
    radial-gradient(
      closest-side at 50% 100%,
      rgba(255, 0, 221, 0.34) 0%,
      rgba(255, 0, 221, 0) 100%
    )
    0% 0% no-repeat padding-box;
  height: 100%;
}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.0625rem;
}

.coinId {
  display: flex;
  align-items: center;
}

.coinId p {
  color: #ac9ba5;
  font-size: 1rem;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bodyContainer {
  max-width: 1440px;
  padding: 0 2.5rem;
  margin: 0 auto;
  width: 100%;
}

.pepeCoinPage {
  max-width: 1600px;
}

.hero {
  font-size: 1.625rem;
  color: var(--text-color);
  margin-bottom: 1.5625rem;
}

.header {
  font-size: 1.625rem;
  color: var(--text-color);
}

.subHeader {
  font-size: 1rem;
  color: var(--primary-color);
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.6875rem 0;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.left {
  align-items: start;
}
.imgBg {
  height: 7.1875rem;
  width: 7.1875rem;
  border-radius: 50%;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.nameCol {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  margin-left: 1.5rem;
}

.fn {
  color: var(--primary-color);
  font-size: 2.125rem;
  margin-right: 1rem;
}

.sn {
  color: var(--text-color);
  font-size: 1.5625rem;
  line-height: 1;
  border: 1px solid var(--primary-color);
  border-radius: 0.625rem;
  padding: 0.625rem 0.875rem;
}

.seeContract {
  color: var(--green-header);
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 0.7688rem;
}

.purpleCircle {
  cursor: pointer;
  position: relative;
  height: 2.8125rem;
  width: 2.8125rem;
  border-radius: 1.40625rem;
  left: calc(100% - 2.125rem);
  top: calc(100% - 2.8125rem);
}

.purpleCircle img {
  cursor: pointer;
  height: 2.8125rem;
  width: 2.8125rem;
}

.scoreRow {
  height: 8.75rem;
}

.scoreCircle {
  position: absolute;
  height: 7.1875rem;
  width: 7.1875rem;
  border-radius: 3.625rem;
  /* background-color: #c67ba6; */
  z-index: 1;
}

.scoreCircle img {
  position: absolute;
  height: 7.1875rem;
  width: 7.1875rem;
  z-index: -1;
  object-fit: cover;
  border-radius: 3.625rem;
  background-color: #49344a;
}

.right {
  align-items: end;
}

.scoreText {
  padding: 1.6875rem 3.5313rem;
  margin-right: 5.875rem;
  border-top-left-radius: 4.0625rem;
  border-bottom-left-radius: 4.0625rem;
  border: 1px solid var(--text-color);
  border-right-width: 0;
}

.scoreNumber {
  font-size: 2.5625rem;
}

.outOf {
  font-size: 0.875rem;
  color: var(--primary-color);
}

.section {
  padding: 3.875rem 0;
}

.subSection {
  margin-top: 2.0625rem;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent linear-gradient(270deg, #e1007f00 0%, #e1007f26 100%)
    0% 0% no-repeat padding-box;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5156rem;
}

.normalText {
  font-size: 1.25rem;
  color: var(--text-color);
}

.charts {
  display: flex;
  flex-direction: row;

  justify-content: space-around;
  align-items: center;
}

.charts a {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
  margin-left: 1rem;
}

.scoreDesc,
.descText {
  margin-top: 2.5625rem;
  opacity: 0.75;
  color: var(--text-color);
  font-size: 0.9375rem;
}

.bulletRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1.4375rem 0;
}

.pinkTick {
  padding: 0 4rem;
  height: 2.0719rem;
  object-fit: contain;
}

.minusBullet {
  padding: 0 4rem;
  height: 3.3262rem;
  object-fit: contain;
}

.descText {
  margin-top: 1.875rem;
}

.note {
  margin-top: 1.4375rem;
  font-size: 0.9375rem;
  letter-spacing: 0px;
  color: #ee5018;
  opacity: 0.75;
}

.tabButton {
  font-size: 1rem;
  color: #ac9ba5;
  margin-bottom: 2.5rem;
}

.sectionBtns div {
  min-width: 23.75rem;
}

.sectionBtns > div:first-child {
  margin-right: 1rem;
}

.info {
  min-width: 200px;
}

.title {
  font-size: 2.25rem;
  margin-bottom: 1rem;
  max-width: 18ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.infoTitle {
  color: #c67ba6;
  font-size: 0.625rem;
  margin-bottom: 1rem;
}

.infoValue {
  color: #ac9ba5;
  font-size: 1rem;
}

.infoWrapper {
  margin-top: 3.25rem;
}

.sectionDisplayBtn svg {
  margin-right: 0.5rem;
  height: 1.25rem;
}

.border {
  height: 1px;
  background-color: #707070;
  margin-top: 3.375rem;
  margin-bottom: 2.25rem;
}

.headerText {
  font-size: 1.625rem;
  margin-bottom: 0.25rem;
}

.pools {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.pools > div {
  padding: 2.125rem;
  min-height: 13rem;
}

.pools > div:nth-child(2) {
  border-left: 1px solid #e1007f;
  border-right: 1px solid #e1007f;
}

.poolTitle {
  font-size: 3.75rem;
  margin-bottom: 1.75rem;
  color: #e1007f;
}

.poolSubtitle {
  color: #ac9ba5;
}

.pools > div {
  position: relative;
}

.pools > div:hover::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #e1007f;
}

.pools > div.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    transparent,
    rgba(225, 0, 127, 0.3)
  );

  z-index: -1;
}

.copy {
  cursor: pointer;
  margin-left: 0.5rem;
}

.infoIcon {
  margin-right: 0.5rem;
}

.priceValue {
  color: #49b7ab;
}

.socialMentions {
  font-size: 7.1875rem;
  color: #e1007f;
  margin-top: 3.625rem;
  margin-bottom: 2.25rem;
}

.poolType {
  border: 1px solid #e1007f;
  padding: 0.75rem;
  min-width: 14.5rem;
  text-align: center;
  margin-right: 0.875rem;
  cursor: pointer;
}

.poolType:hover {
  background-color: rgba(225, 0, 127, 0.5);
}

.poolType.active {
  background-color: #e1007f;
}

.poolTypes {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.headers {
  font-size: 0.625rem;
  color: #c67ba6;
  margin-bottom: 0.375rem;
}

.headers,
.row {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1fr 1fr 0.8fr;
}

.poolRow,
.poolHeader {
  grid-template-columns: 1.5fr 0.75fr 1.2fr 1.2fr 0.5fr;
}

.row {
  padding: 1.5rem 0.8125rem 1.125rem;
  background-color: rgba(255, 255, 255, 0.15);
  margin-bottom: 0.625rem;
}

.rowItem {
  color: #ac9ba5;
  font-size: 1rem;
}

.row .rowItem:last-child {
  text-align: right;
  color: #e1007f;
  text-decoration: underline;
  cursor: pointer;
}

.row .rowItem:last-child svg {
  margin-left: 0.5rem;
}

.todaysTelegramMentions {
  color: #ac9ba5;
  font-size: 1rem;
  margin-bottom: 6.8125rem;
}

.seeTransactionsHistory {
  font-size: 10px;
  color: #e1007f;
  text-align: center;
  margin: 2rem 0;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 3rem;
}

@media (max-width: 1200px) {
  .pools {
    grid-template-columns: repeat(1, 1fr);
  }

  .info {
    min-width: auto;
  }

  .info:last-child {
    margin-left: 10px;
  }

  .pools > div {
    border-left: 1px solid #e1007f;
    border-right: none !important;
    min-height: auto;
  }
}

@media (max-width: 768px) {
  .pepeAction {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sectionBtns {
    margin-top: 1rem;
    width: 100%;
  }

  .poolTitle {
    font-size: 38px;
  }

  .sectionBtns div {
    min-width: auto;
    width: 100%;
    font-size: 6px !important;
  }

  .bodyContainer {
    padding: 1.5rem 1rem;
  }

  .header {
    font-size: 1.375rem;
  }

  .subHeader {
    font-size: 0.875rem;
  }

  .section {
    padding: 1.875rem 0;
  }

  .normalText {
    font-size: 1rem;
  }

  .infoContainer {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }

  .left,
  .right {
    width: 100%;
    align-items: flex-start;
  }

  .nameCol {
    margin-left: 0;
  }

  .imgBg {
    margin-right: 1rem;
  }

  .fn {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .sn {
    font-size: 1.125rem;
    padding: 0.5rem 0.75rem;
  }

  .seeContract {
    font-size: 0.75rem;
    margin-left: 0.6rem;
  }

  .purpleCircle {
    left: calc(100% - 2.5rem);
    top: calc(100% - 3.125rem);
    display: none;
  }

  .scoreText {
    padding: 1rem 2.5rem;
    margin-right: 3.5rem;
  }

  .scoreNumber {
    font-size: 2rem;
  }

  .outOf {
    font-size: 0.75rem;
  }

  .scoreDesc,
  .descText {
    font-size: 0.75rem;
    margin-top: 1.5rem;
  }

  .bulletRow {
    padding: 1rem 0;
  }

  .detailsRow {
    flex-direction: column;
    align-items: flex-start;
  }

  .detailsRow div:last-child {
    font-size: 0.875rem;
    margin-top: 0.425rem;
  }

  .charts {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .charts a {
    margin-left: 0;
    margin-right: 0.875rem;
  }

  .pinkTick {
    padding: 0 1.675rem;
    height: 1.5rem;
  }

  .minusBullet {
    padding: 0 1.675rem;
    height: 2.5rem;
  }

  .note {
    margin-top: 1rem;
    font-size: 0.75rem;
    font-size: 1rem;
  }

  .sn {
    font-size: 0.875rem;
  }

  .imgBg {
    height: 5.1875rem;
    width: 5.1875rem;
  }

  .seeContract {
    font-size: 0.75rem;
  }

  .poolRow,
  .poolHeader {
    grid-template-columns: 1.3fr 1.3fr 1.2fr 0.5fr;
  }

  .rowItem {
    font-size: 0.645rem;
    display: flex;
    align-items: center;
  }

  .copy svg {
    height: 0.875rem;
  }

  .ago svg {
    height: 0.875rem;
  }

  .coinId p {
    max-width: 18ch;
  }

  .headers,
  .row {
    display: grid;
    gap: 10px;
    grid-template-columns: 1.2fr 1fr 1fr 0.2fr;
  }

  .pintSwapHeaders,
  .pintSwapRow {
    grid-template-columns: 1.2fr 1.2fr 1fr 1fr 0.2fr;
  }

  .rowItem:last-child svg {
    width: 0.875rem;
  }
}

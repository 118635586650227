.tab {
  border-radius: 0.75rem 1rem 0rem 0rem;
  background-color: #c67ba6;
  font-size: 0.9375rem;
  padding: 1rem 1.5rem;
  display: inline-block;
  margin-right: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.tab.active {
  background-color: #e1007f;
}

@media (max-width: 768px) {
  .tab {
    font-size: 0.625rem;
    padding: 0.75rem 1rem;
  }
}
